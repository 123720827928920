import React from 'react'

import AdUnit from '~/components/AdUnit'

export default function FeedItemAd({ad}) {
  return (
    <div
      className="overflow-hidden bg-paper rounded-2xl p-4 flex flex-col items-center justify-center"
      data-testid="feed/FeedItemAd">
      <div style={{height: 250, width: 300}}>
        <AdUnit
          name="bc-in-feed"
          targets={{batch: ad.batch}} />
      </div>
      <div className="text-secondary text-sm font-normal">
        SPONSORED
      </div>
    </div>
  )
}
