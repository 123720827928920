import {useEffect, useReducer, useRef} from 'react'

function defaultStyleContainer(heights) {
  switch (heights.length) {
    case 0:
    case 1:
      return {}
    default:
      return {position: 'relative', height: Math.max(...heights)}
  }
}

function defaultStyleItem(heights) {
  switch (heights.length) {
    case 0:
    case 1:
      return {alignSelf: 'flex-start', width: '100%'}
    default:
      return {position: 'absolute', alignSelf: 'flex-start', width: '100%'}
  }
}

function zeroes(len) {
  const res = []

  while (res.length < len) {
    res.push(0)
  }

  return res
}

function createReducer(breaks, computeColumns, refItems) {
  return (state, {type, value}) => {
    switch (type) {
      case 'compute': {
        const {reset} = value

        if (state.break === -1) {
          return state
        }
        if (!reset && state.items.length === refItems.current.length) {
          return state
        }

        const items = reset ? [] : [...state.items]
        const heights = reset ? zeroes(breaks[state.break][1]) : [...state.heights]
        const rows = reset ? zeroes(breaks[state.break][1]) : [...state.rows]

        const itemsIndexBegin = items.length
        const itemHeights = []

        for (let i = itemsIndexBegin; i < refItems.current.length; i++) {
          const el = refItems.current[i]
          const {height} = el?.getBoundingClientRect() || {height: 0}

          itemHeights.push(height)
        }
        const cols = computeColumns([...heights], itemHeights)

        for (let i = itemsIndexBegin; i < refItems.current.length; i++) {
          const height = itemHeights[i - itemsIndexBegin]
          const col = cols[i - itemsIndexBegin]
          const style = defaultStyleItem(heights)

          if (heights.length > 1) {
            style.top = heights[col]
            style.left = `${100 * col / heights.length}%`
          }
          heights[col] += height
          items[i] = {height, style, col, row: rows[col]++}
        }

        return {...state, items, heights, rows}
      }
      case 'width': {
        const {width} = value

        for (let i = 0; i <= breaks.length; i++) {
          if (width >= breaks[i][0]) {
            return i === state.break ? state : {...state, break: i}
          }
        }

        return state
      }
      default:
        throw new Error('Unexpected case')
    }
  }
}

export function useMasonry({watches, breaks, algorithm = 'greedy'}) {
  const computeColumns = require(`./compute/${algorithm}`).default
  const refItems = useRef([])
  const [state, dispatch] = useReducer(createReducer(breaks, computeColumns, refItems), {items: [], break: -1, heights: [], rows: []})

  const {items, heights} = state
  const compute = (reset = false) => dispatch({type: 'compute', value: {reset}})
  const setWidth = width => dispatch({type: 'width', value: {width}})
  const refItemAt = i => el => refItems.current[i] = el
  const styleItemAt = i => i in items ? items[i].style : defaultStyleItem(heights)
  const offsetItemAt = i => i in items
    ? (heights.length > 1 ? `${items[i].row}-${items[i].col}` : i)
    : i
  const styleContainer = () => defaultStyleContainer(heights)

  useEffect(() => {
    const handleResize = () => {
      // use innerWidth to include scroll bar'size when calculate masonry layout
      setWidth(window.innerWidth || window.document.body.clientWidth)
      compute()
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    compute(true)
  }, [state.break])

  useEffect(() => {
    compute()
  }, watches)

  return {
    state,
    refItems,
    refItemAt,
    styleItemAt,
    offsetItemAt,
    styleContainer,
  }
}
