import 'intersection-observer'

import React, {useEffect, useRef} from 'react'

export function WaypointOnce({children, onEnter}) {
  const ref = useRef(null)

  useEffect(() => {
    const handleIntersection = (entries, observer) => {
      entries.forEach(({isIntersecting, target}) => {
        if (isIntersecting) {
          onEnter()
          observer.unobserve(target)
        }
      })
    }
    const observer = new IntersectionObserver(handleIntersection)

    observer.observe(ref.current)

    return () => {
      observer.disconnect()
    }
  }, [])

  return React.cloneElement(children, {ref})
}

export default WaypointOnce
