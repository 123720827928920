import React from 'react'
import Head from 'next/head'
import {useRouter} from 'next/router'
import {useQuery} from '@apollo/client'

import {useTranslation} from '~/i18n'
import config from '~/config'
import Layout from '~/layouts/default'
import qlPosts from '~/graphql/Posts'

import Feed from '~/components/feed/Feed'


export function usePosts(search, before) {
  const {loading, data, fetchMore} = useQuery(qlPosts, {
    variables: {
      search: search || undefined,
      before: parseInt(before) || undefined,
    },
  })
  const ready = !loading && typeof data !== 'undefined'

  if (!ready) {
    return {ready}
  }

  const {posts} = data
  const postsLoadMore = () => fetchMore({variables: {before: posts.range.data[posts.range.data.length - 1].id}})

  return {ready, posts, postsLoadMore}
}

export default function HomePage() {
  const {t} = useTranslation('common')
  const router = useRouter()
  
  const {ready, posts, postsLoadMore} = usePosts(
    router.query.s,
    router.query.before,
  )

  if (!ready) {
    return null
  }

  const canonicalUrl = `${config.baseurl}/`
  const nextBefore = posts.range.has_before
    ? posts.range.data[posts.range.data.length - 1].id
    : null
  const nextUrl = nextBefore ? `${canonicalUrl}?before=${nextBefore}` : null


  return (
    <React.Fragment><Head>
      <title>{`${t('site.title')} - ${t('site.tagline')}`}</title>
      <meta
        key="description"
        name="description"
        content={t('site.tagline')} />
      <link
        key="canonical"
        rel="canonical"
        href={canonicalUrl} /> 
      {nextUrl &&
         <link
           key="next"
           rel="next"
           href={nextUrl} />
      }
      
    </Head>
    <div>
      <Feed
        key={`feed-index-${router.query.s ?? ''}`}
        posts={posts}
        nextUrl={nextUrl}
        nextBefore={nextBefore}
        onLoadMore={postsLoadMore} />
    </div></React.Fragment>
  )
}

HomePage.Layout = Layout
HomePage.getApolloQueryProperties = ({ctx}) => ({
  query: qlPosts,
  variables: {
    search: ctx.query.s || undefined,
    before: ctx.query.before || undefined,
  },
})

export const getServerSideProps = async ({res}) => {
  res.setHeader('Cache-Control', 'max-age=60')

  return {props: {}}
}