import gql from '~/modules/graphql/gql'
import fragments from './fragments'

export default gql`
  query Posts($before: Int, $search: String = "") {
    posts(search: $search) {
      range(before: $before, limit: 12) {
        has_before
        data {
          ...PostFragment
        }
      }
    }
  }

  ${{fragments}}
`

export const withDetails = gql`
  query PostsWithDetails($before: Int, $search: String = "") {
    posts(search: $search) {
      range(before: $before, limit: 12) {
        has_before
        data {
          ...PostFragment
          post_content
          categories {
            range {
              data {
                term_id
                name
              }
            }
          }
          tags {
            data {
              term_id
              name
              slug
            }
          }
          related_posts {
            range(limit: 3) {
              data {
                ...PostFragment
              }
            }
          }
        }
      }
    }
  }

  ${{fragments}}
`
