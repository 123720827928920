import React from 'react'
import Link from 'next/link'

import {sendGtagEvent} from '../../modules/ga'
import {useMasonry} from '~/modules/masonry'
import WaypointOnce from '~/components/WaypointOnce'
import FeedItemAd from '~/components/feed/FeedItemAd'
import FeedItemPost from '~/components/feed/FeedItemPost'

function buildFeedItems({posts, ad: {offset, loop}}) {
  return posts.range.data
    .map(
      (post, i) => i % loop === offset
        ? [{type: 'ad', data: {batch: 1 + Math.floor(i / loop)}}, {type: 'post', data: post}]
        : [{type: 'post', data: post}],
    )
    .reduce((z, a) => ([...z, ...a]), [])
}

export default function Feed({
  posts, nextUrl, nextBefore, onLoadMore, disableAd = false,
}) {
  const masonry = useMasonry({
    watches: [posts],
    breaks: [
      [1920, 4],
      [1280, 3],
      [992, 2],
      [0, 1],
    ],
  })
  const onLoadMoreWithGA = action => () => {
    onLoadMore()
    sendGtagEvent(action, {category: 'feed/Feed', label: `more-offset-${posts.range.data.length}`})
  }

  const feedItems = disableAd
    ? buildFeedItems({posts, ad: {offset: 1, loop: 1}})
    : buildFeedItems({posts, ad: {offset: 2, loop: 12}})

  return (
    <div
      className="container xl:max-w-screen-lg"
      data-testid="feed/Feed">
      <div
        className="flex flex-wrap -mx-2"
        style={masonry.styleContainer()}>
        {feedItems.map(({type, data}, i) => {
          switch (type) {
            case 'post':
              return (
                <div
                  key={`post-${data.id}`}
                  ref={masonry.refItemAt(i)}
                  className="grow-0 max-w-full md:max-w-1/2 lg:max-w-1/3 xl:max-w-1/4 px-2 pb-4"
                  style={masonry.styleItemAt(i)}
                  data-testid="feed/Feed/post">
                  <Link
                    href="/[id]"
                    as={`/${data.id}`}>
                    <a
                      className="text-content hover:text-content"
                      onClick={() => sendGtagEvent('click', {category: 'feed/Feed', label: `post-offset-${masonry.offsetItemAt(i)}`})}>
                      <FeedItemPost post={data} />
                    </a>
                  </Link>
                </div>
              )
            case 'ad':
              return (
                <div
                  key={`ad-batch-${data.batch}`}
                  ref={masonry.refItemAt(i)}
                  className="grow-0 max-w-full md:max-w-1/2 lg:max-w-1/3 xl:max-w-1/4 px-2 pb-4"
                  style={masonry.styleItemAt(i)}
                  data-testid="feed/Feed/ad">
                  <FeedItemAd ad={data} />
                </div>
              )
            default:
              throw new Error(`Unexpected feedItem.type: ${type}`)
          }
        })}
      </div>
      <div className="flex justify-center">
        {nextBefore && (
          <WaypointOnce
            onEnter={onLoadMoreWithGA('waypoint')}
            key={`LoadMore-${nextBefore}`}>
            <div className="py-8">
              <a
                className="text-black hover:text-black"
                href={nextUrl}
                data-testid={`feed/Feed/more-offset-${posts.range.data.length}`}
                onClick={e => {
                  e.preventDefault()
                  onLoadMoreWithGA('click')()
                }}>
                Load More
              </a>
            </div>
          </WaypointOnce>
        )}
      </div>
    </div>
  )
}
