import React from 'react'
import clsx from 'clsx'

import {useIntersectionObserver} from '~/utils/observer'
import config from '~/config'

function getThumbnail(thumbnail, hd) {
  const original = thumbnail
  const medium = thumbnail.sizes.find(({size}) => size === 'medium')
  const large = thumbnail.sizes.find(({size}) => size === 'large')

  if (hd) {
    return [large || original, original, original]
  }
  else {
    return [medium || original, large || original, original]
  }
}

function useThumbnail(post, hd = false) {
  if (post.thumbnail === null) {
    return {
      src: `${config.cdn}/static/photo404.jpg`,
      src2x: `${config.cdn}/static/photo404.jpg`,
      src3x: `${config.cdn}/static/photo404.jpg`,
      width: 800,
      height: 400,
      alt: '',
    }
  }

  const [thumbnail, thumbnail2x, thumbnail3x] = getThumbnail(post.thumbnail, hd)
  const {width, height, src} = thumbnail
  const {src: src2x} = thumbnail2x
  const {src: src3x} = thumbnail3x
  const alt = post.post_title

  return {
    src, src2x, src3x, width, height, alt,
  }
}

function Thumbnail({post, hd, nolazy, zoomEffect = false}) {
  const {
    src, src2x, src3x, width, height, alt,
  } = useThumbnail(post, hd)
  const {ref, intersected} = useIntersectionObserver()

  return (
    <div
      ref={ref}
      className="relative overflow-hidden bg-paper rounded-2xl"
      style={{paddingBottom: `${100 * height / width}%`}}
      data-testid="Thumbnail">
      {(nolazy || intersected) && (
        <picture>
          {['.jpg', 'jpeg'].includes(src.slice(-4)) && (
            <source
              type="image/webp"
              srcSet={`${src}.webp, ${src2x}.webp 2x, ${src3x}.webp 3x`} />
          )}
          <img
            className={clsx('absolute w-full', {'transition-transform hover:scale-105': zoomEffect})}
            src={src}
            alt={alt} />
        </picture>
      )}
    </div>
  )
}

export default Thumbnail
