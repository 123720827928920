import 'intersection-observer'

import {useEffect, useState, useRef} from 'react'

export function useIntersectionObserver() {
  const ref = useRef(null)
  const [intersected, setIntersected] = useState(false)

  useEffect(() => {
    function handleIntersection(entries, observer) {
      entries.forEach(({isIntersecting, target}) => {
        if (isIntersecting) {
          setIntersected(true)
          observer.unobserve(target)
        }
      })
    }
    const observer = new IntersectionObserver(handleIntersection)

    observer.observe(ref.current)

    return () => {
      observer.disconnect()
    }
  }, [])

  return {ref, intersected}
}