export default function compute(heights, itemHeights) {
  const cols = []

  for (let i = 0; i < itemHeights.length; i++) {
    const col = findMinIndex(heights)

    cols.push(col)
    heights[col] += itemHeights[i]
  }

  return cols
}

function findMinIndex(nums) {
  let mi = 0

  for (let i = 0; i < nums.length; i++) {
    if (nums[i] < nums[mi]) {
      mi = i
    }
  }

  return mi
}
