import React from 'react'

import {useTranslation} from '~/i18n'
import Thumbnail from '~/components/post/Thumbnail'

export default function FeedItemPost({post}) {
  const {t} = useTranslation('datetime')

  return (
    <div
      className="card overflow-hidden bg-primary"
      data-testid="feed/FeedItemPost">
      <Thumbnail
        post={post}
        zoomEffect />
      <div className="p-4">
        <div className="font-fc text-sm font-normal mb-4">
          {t('date.short', {date: new Date(post.post_date)})}
        </div>
        {post.post_title && (
          <h3
            className="font-fc font-bold"
            data-testid="feed/FeedItemPost/title">
            {post.post_title}
          </h3>
        )}
        {post.post_excerpt && (
          <p
            className="py-2"
            data-testid="feed/FeedItemPost/excerpt">{post.post_excerpt}</p>
        )}
        
      </div>
    </div>
  )
}
